<template>

<div class="card m-3" style="width: 18rem;">
  <div>
    <img
      :data-bs-toggle="isAllowedToEdit() ? 'modal' : ''"
      v-b-tooltip.hover
      :title="editTooltip()"
      data-bs-target="#TicketEditModal"
      @click="editTicket"
      width="200px" height="180px"
      :src="ticket.fileImageAddress"
      id="testIMG"
      class="card-img-top" alt="..."
    >
    <div class="dropdown options">
      <button class="btn optionsButton" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-target="dropdownMenuButton1" aria-expanded="false">
        <i class="bi bi-three-dots"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li
        v-b-tooltip.hover
        :title="!isAllowedToEdit() ? manageStore.lang('cards.modelcard.options.delete_hint') : ''"
        >

        <button
          :disabled="!isAllowedToEdit()"
          @click="removeTicket" data-bs-toggle="modal"
          data-bs-target="#removeTicket" class="dropdown-item"
         
          >{{ manageStore.lang("cards.modelcard.options.delete_ticket") }}</button>
          
        </li>
      </ul>
    </div>
  </div>
  

  <div class="card-body bg-primary3">
    <h4
      data-bs-toggle="modal"
      data-bs-target="#TicketEditModal"
      @click="editTicket" class="card-title fs-5 text-white fw-bold">
      {{ ticket.ticket_name }}
    </h4>
    <div class="d-flex justify-content-evenly align-items-center mt-4 py-2">
      <img
      class="rounded-circle"
      width="30px"
      :src="ticket.assignee == undefined || ticket.assignee == '' ? manageStore.baseUserImageUrl+'XXXX' : manageStore.baseUserImageUrl+ticket.assignee"
      alt="printer profile">
      <!-- <i class="fi fi-rr-angle-double-small-up fs-4 text-danger"></i> -->
       <div class="rounded border border-primary2 border-2" style="width: 45px;">
        <img v-if="ticket.priority == 0" src="../../assets/svg/priority_low.svg" width="25px" alt="">
        <img v-if="ticket.priority == 1" src="../../assets/svg/priority_medium.svg" width="25px" alt="">
        <img v-if="ticket.priority == 2" src="../../assets/svg/priority_high.svg" width="25px" alt="">
       </div>
       <div class="'w-50" v-b-tooltip.hover :title="statusTooltip">
        <button
          @click="setAssigningTicket"
          data-bs-toggle="modal"
          :data-bs-target="ticket.status == 0 ? '#assigneeModal': '#setPrintedModal'"
          :disabled="canSetTicketToPrinted()"
          v-b-tooltip.hover
          :class="'btn text-white fw-bold bg-' + ticketStore.stateColors[ticket.status]"
        >      
          {{ ticketStore.states[ticket.status] }}
        </button>
       </div>
       
    </div>
  </div>
</div>

</template>

<script>

import  {PrintingTicket} from "../../other/DataStructs.ts"
import { useTicketsStore } from '@/stores/tickets.ts';
import { useManageStore } from '@/stores/manage';
import { useAuthStore } from '@/stores/authStore.ts';

export default {
  name: 'ModelCard',

  props:{
    ticket: PrintingTicket,
  },

  setup(props){
    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();
    const authStore = useAuthStore();
    const priorityLevelNames = ticketStore.priorityLevelNames;

    // setTimeout(() => {  
    //   const jpeg = ticketStore.getJPEG(ticketStore.models[0]);
    //   const img = document.getElementById("testIMG");
    //   img.src = jpeg;
    //   console.log(jpeg);
    // }, 3000);

    function isAllowedToEdit(){
      return authStore.preferred_username == props.ticket.client || authStore.user_role == authStore.userRoles[1];
    }

    function editTicket(){
      ticketStore.editTicket = Object.assign({}, props.ticket);
      ticketStore.imageGenerated = false;
      ticketStore.uploadProgress = 0;
      ticketStore.isUploading = false;
      ticketStore.uploaded = false;
      ticketStore.sendWithBar = false;
    }

    function setAssigningTicket(){
      ticketStore.assigningTicket = props.ticket.id;
      manageStore.userAssign = authStore.preferred_username;
    }

    function canSetTicketToPrinted(){
      if(props.ticket.status == 0){
        return authStore.user_role != authStore.userRoles[1];
      }
      else{
        return authStore.user_role != authStore.userRoles[1] || props.ticket.assignee != authStore.preferred_username;
      }
      
    }
    function editTooltip(){
      let tooltip = '';
      if(!isAllowedToEdit()){
        tooltip = manageStore.lang('cards.modelcard.tooltips.canEdit');
      }
      return tooltip;
    }
    function statusTooltip(){
      let text = "";
      if(canSetTicketToPrinted()){
        if(props.ticket.status == 0){
          text = manageStore.lang("cards.modelcard.tooltips.assignTicket");
        }
        else{
          text = manageStore.lang("cards.modelcard.tooltips.setPrinted");
        }
      }
      return text;
    }

    function removeTicket(){
      ticketStore.editTicket = props.ticket
    }

    return{
        ticketStore,
        manageStore,
        priorityLevelNames,
        editTicket,
        setAssigningTicket,
        isAllowedToEdit,
        removeTicket,
        canSetTicketToPrinted,
        statusTooltip,
        editTooltip
    }
  }
}

</script>

<style scoped>

.card-img-top, .card-title{
  cursor: pointer;
}
.options{
  position: absolute;
  right: 0;
  top: 0;
}

.optionsButton{
  cursor: pointer;
  background-color: rgba(255, 255, 255);
  opacity: 60%;

}
.optionsButton:hover{
  opacity: 95%;
}

.iconbutton{
  cursor: pointer;
}

.btn.disabled {
    pointer-events: auto;
}

</style>