<template>

<div class="mx-5 my-3">
    <b-row>
      <b-col cols="7">
        <form class="text-start ">
          <div class="d-flex">
            <b-col>
              <label for="uploadFile" class="form-label fs-5">{{ manageStore.lang("dialogs.editTicketModal.change_stl") }}</label>
              <input v-if="!ticketStore.selectedFile" @change="onFileSelected" style="" ref="fileInput" class="form-control form-control-sm" id="uploadFile" type="file" accept=".stl" :disabled="!canBeEdited"/>
              <div v-else class="progress glow-button" style="height: 1.5rem;">
                  <div :style="'width:'+ ticketStore.uploadProgress +'%;'" class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                      {{ ticketStore.uploadProgress }}%
                  </div>
              </div>
            </b-col>
            <b-col class="d-flex justify-content-center align-items-center">
              <button @click="getSTLFile" style="width: 80px; height: 50px; font-size: medium;" class="btn btn-primary2 text-white" type="button"><i class="bi bi-file-earmark-arrow-down"></i> STL</button>
            </b-col>
          </div>
          <div class="form-group">
            <label for="modelname" class="fs-5">{{ manageStore.lang("dialogs.uploadModal.model_name") }}</label>
            <input v-model="ticketStore.editTicket.ticket_name" type="text" class="form-control" id="modelname" placeholder="Enter Model Name" :disabled="!canBeEdited">
          </div>
          <div class="form-group">
            <label class="fs-5">{{ manageStore.lang("dialogs.uploadModal.printing_color") }}</label>
            <select v-model="ticketStore.editTicket.material" class="form-select" aria-label="select printing material" :disabled="!canBeEdited">
                <option v-for="m in printingMaterials" :key="m.index" :value="m">{{ m }}</option>
            </select>
          </div>

          <b-row class="d-flex justify-content-center mt-4">
            <b-col>
              <label class="fs-5">{{ manageStore.lang("dialogs.uploadModal.priority") }}</label>
              <select v-model="ticketStore.editTicket.priority" class="form-select" aria-label="select priority" :disabled="!canBeEdited">
                  <option v-for="p in priorityLevels" :key="p.index" :value="priorityLevels[p] " >{{ priorityLevelNames[p] }}</option>
              </select>
            </b-col>
            <b-col>
              <label for="delivery-datepicker" class="fs-5">{{ manageStore.lang("dialogs.uploadModal.delivery_date") }}</label>
              <b-form-datepicker v-model="ticketStore.editTicket.deliverydate" id="delivery-datepicker" size="sm" :disabled="!canBeEdited"></b-form-datepicker>
            </b-col>
          </b-row>
        </form>
      </b-col>

      <b-col>
        <div>
          <label class="fs-5">{{ manageStore.lang("dialogs.uploadModal.ticket_thumbnail") }}</label>
          <img v-if="!ticketStore.imageGenerated" :src="ticketStore.editTicket.fileImageAddress" alt="">
          <canvas v-else id="threeviewport_edit"></canvas>
        </div>
        <div class="mt-4">
          <b-col>
              <p class="text-start">{{ manageStore.lang("dialogs.editTicketModal.client") }}</p>
              <div class="border rounded d-flex align-items-center disabledForm">
                <p class="text-start m-2">{{ ticketStore.editTicket.client ? ticketStore.editTicket.client : detailPlaceholder }}</p>
              </div>
            </b-col>

            <b-col class="mt-4">
              <p class="text-start">{{ manageStore.lang("dialogs.editTicketModal.assignee") }}</p>
              <div class="border rounded disabledForm">
                <p class="text-start m-2">{{ ticketStore.editTicket.assignee ? ticketStore.editTicket.assignee : detailPlaceholder }}</p>
              </div>
            </b-col>
        </div>
      </b-col>
    </b-row>
  
  <div v-if="!ticketStore.isUploading || !ticketStore.sendWithBar" class="d-flex justify-content-center m-4 gap-4">
    <button @click="upload()" :data-bs-dismiss=" ticketStore.sendWithBar ? '' : 'modal'" type="button" :class="'btn btn-lg btn-primary text-white ' + (isValidTicket ? '' : ' disabled')">{{ canBeEdited ? manageStore.lang("dialogs.uploadModal.send"):  manageStore.lang("dialogs.editTicketModal.noSend") }}</button>
  </div>
  <div v-else class="progress mt-4 glow-button" style="height: 1.5rem;">
    <div :style="'width: ' + ticketStore.uploadProgress + '%;'" class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
      {{ ticketStore.uploadProgress }}%
    </div>
  </div>
  <div v-if="ticketStore.uploaded">
    <h5 class="mt-3">Upload successfull - <i class="bi bi-check-circle-fill"></i></h5>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">close</button>
  </div>

</div>

</template>

<script>

import { useTicketsStore } from "@/stores/tickets.ts";
import { useManageStore } from "@/stores/manage";
import {computed, ref} from 'vue';
import {setViewport, initViewport} from '@/components/ThreeScene/3dloader';

export default{
  name: 'UploadModal',
  setup(){
    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();

    const printingMaterials = ticketStore.printingMaterials;
    const priorityLevels = ticketStore.priorityLevels;
    const priorityLevelNames = ticketStore.priorityLevelNames;
    const fileInput = ref();
    const detailPlaceholder = "not_set";

    let stlFiles;

    const ticket_name = computed(()=>{
      const temp = ticketStore.editTicket.model_filepath.split("_");
      return temp[temp.length-1];
    });

    const canBeEdited = computed(()=>{
      return ticketStore.editTicket.status == 0;
    });
  
    function onFileSelected(e) {
      stlFiles = e.target.files || e.dataTransfer.files;
      ticketStore.currentSTLFile = stlFiles[0];
      ticketStore.sendWithBar = true;

      if (!stlFiles.length){
        return;
      }

      ticketStore.editTicket.model_filepath = stlFiles[0].name;
      if(ticketStore.editTicket.ticket_name == ""){
        ticketStore.editTicket.ticket_name = stlFiles[0].name;
      }

      //upload progress
      renderViewport();
    }

    function renderViewport(){
      //get stl file
      ticketStore.imageGenerated = true;
      const url = URL.createObjectURL(ticketStore.currentSTLFile);

      //render viewport
      setTimeout(()=>{
        initViewport(url, "threeviewport_edit");
      }, 100)
      setTimeout(()=>{
        setViewport(0, 0, 260, 140, "threeviewport_edit");//260, 140
      }, 700);
    }


    const isValidTicket = computed(() =>{      
      return ticketStore.editTicket.ticket_name.length != 0
      && ticketStore.editTicket.material !== ''
      && ticketStore.editTicket.priority !== ''
      && ticketStore.editTicket.deliverydate !== ''
    });

    function getSTLFile(){
      ticketStore.downloadSTL(ticketStore.editTicket)
    }

    function upload(){
      ticketStore.isUploading = true;
      if(ticketStore.sendWithBar){
        const checkuploadEdit = setInterval(()=>{
          const max = 4;
          const min = 1;
          const speed = Math.floor(Math.random() * (max - min + 1)) + min;

          if(ticketStore.uploadProgress < 100){
            ticketStore.uploadProgress += speed;
          }
          else{
            ticketStore.uploadProgress = 100;
            clearInterval(checkuploadEdit);
            setTimeout(() => {
              ticketStore.uploaded = true;
            }, 1000);
            sendData();
          }
        },50);
      }
      else{
        sendData();
      }
      fileInput.value.value = null

      
    }

    function sendData(){
      if(ticketStore.imageGenerated){
        const canvas = document.getElementById("threeviewport_edit");
        //render canvas
        const dataURL = canvas.toDataURL('image/jpeg');
        ticketStore.editTicket.fileImageAdress = dataURL;
      }
      //../../../../wm_aut_3d_printer_backend/_3D_API/database/data_files/jpeg_files/roboter.jpg

      ticketStore.putTicket(ticketStore.editTicket);
    }


    return{
      printingMaterials,
      priorityLevels,
      priorityLevelNames,
      isValidTicket,
      ticketStore,
      manageStore,
      sendData,
      onFileSelected,
      fileInput,
      ticket_name,
      detailPlaceholder,
      getSTLFile,
      upload,
      canBeEdited
    }
  }
}
</script>

<style scoped>

  .form-group{
    margin-top: 40px;
  }

  .modal-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .disabledForm{
    background-color: #e9ecef;
  }

</style>