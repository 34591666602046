import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '@/stores/authStore';



export class PrintingTicket{

    //unique id
    id: string;

    /**client who send the printing request*/
    client: string = "";
    /**assignee who is responsible for printing the ticket */
    assignee: string = "";
    /**printer*/
    printer: string = "";

    /**model name**/
    ticket_name: string = ""

    fileImageAddress: string = ""

    model_filepath: string = ""

    /**date on which the ticket should be finished*/
    deliverydate: string = "";
    priority: number = 0;

    /**0: not assigned | 1: in progress | 2: printed*/
    status: number = 0;

    material: string = "";

    /**creates a new ticket */
    constructor(client: string, assignee: string, printer: string, ticket_name: string, priority: number, deliverydate: string, material: string, fileImageAdress: string, model_filepath: string){
        this.id = uuidv4();

        this.setClient(client);
        this.assignee = assignee;
        this.printer = printer;
        this.setTicket_name(ticket_name);
        this.setPriority(priority);
        this.setDeliveryDate(deliverydate);
        this.setMaterial(material);
        this.setFileImage(fileImageAdress);
        this.setFileAdress(model_filepath);
    }

    setClient(client: string){
        this.client = client;
    }

    setTicket_name(ticket_name: string){
        this.ticket_name = ticket_name;
    }

    setPriority(priority: number){
        this.priority = priority;
    }
    
    setDeliveryDate(deliverydate: string){
        this.deliverydate = deliverydate;
    }
    setMaterial(material: string){
        this.material = material;
    }


    setStatus(status: number){
        this.status = status;
    }

    setFileImage(fileImageAddress: string){
        this.fileImageAddress = fileImageAddress;
    }

    setFileAdress(model_filepath: string){
        this.model_filepath = model_filepath;
    }
}

export class user{

    authStore = useAuthStore();

    private name: string = "";

    private role: string = "";

    constructor(name: string, role: string){
        this.setName(name);
        this.setRole(role);
    }

    public setName(name:string){
        this.name = name;
    }
    public setRole(role:string){
        if(this.authStore.userRoles.includes(role)){
            this.role = role;
        }
        else{
            throw "not a valid role name!";
        }
    }
}

export class Printer{

    fileImageAddress = "";
    name = "";
    ticketQueue = [] as string[];
    colorInUse = ""

    constructor(name:string, fileImageAddress: string, colorInUse: string){
        this.name = name;
        this.fileImageAddress = fileImageAddress;
        this.colorInUse = colorInUse;
    }
}