<template>
    <div class="viewOffset">
        <div id="plotMaterials"></div>
        <div id="plotMaterialHistory"></div>
    </div>
</template>

<script>
import { useStatsStore } from '@/stores/stats';
import { useTicketsStore } from '@/stores/tickets';
import Plotly from 'plotly.js/dist/plotly'
import { onMounted } from 'vue';

export default {
 name: 'StatsView',
 setup(){
     const ticketStore = useTicketsStore();
     const statsStore = useStatsStore()
    
     onMounted(()=> {
        initializeChartData();
    });


     async function initializeChartData() {
        const distributionColors = await statsStore.getTicketColors();

        var xValue = ticketStore.printingMaterials;
        var yValue = distributionColors;

        var colors = ticketStore.printingColorsStats;

        var traceMats = {
            x: xValue,
            y: yValue,
            type: 'bar',
            textposition: 'auto',
            hoverinfo: 'auto',
            marker: {
                color: colors,
                opacity: 0.6,
                line: {
                    color: 'rgb(8,48,107)',
                    width: 1.5
                }
            }
        };
        var dataMats = [traceMats];

        var layout3 = {
            title: 'Colors in use',
            barmode: 'stack'
        };
        Plotly.newPlot('plotMaterials', dataMats, layout3);


        const dataHistory = [];

        const colorsUsage = [
            {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
            },
            {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
            },
            {
                x: [1, 2, 3, 4],
                y: [10, 15, 54, 65],
            },
            {
                x: [1, 2, 3, 4],
                y: [20, 15, 43, 23],
            },
            {
                x: [1, 2, 3, 4],
                y: [10, 32, 54, 23],
            },
            {
                x: [1, 2, 3, 4],
                y: [43, 12, 54, 32],
            },
        ];

        for (let i = 0; i < ticketStore.printingMaterials.length; i++) {
            const mat = ticketStore.printingMaterials[i];
            var trace = {
                x: colorsUsage[i].x,
                y: colorsUsage[i].y,
                type: 'scatter',
                name: mat
            };
            dataHistory.push(trace);
        }

        const layoutHistory = {
            title: 'Colors printed over time',
            barmode: 'stack'
        };

        Plotly.newPlot('plotMaterialHistory', dataHistory, layoutHistory);
     }
 },
}
</script>

<style scoped>

.viewOffset{
    margin-top: 130px;
}

</style>