import { defineStore} from "pinia";
import axios from "axios";
import { useTicketsStore } from "./tickets";

export const useStatsStore = defineStore("statsStore", {
    state: () =>{
        return {
            
        };
    },
    actions: {
        async getTicketColors(){
            const ticketStore = useTicketsStore();

            const varname = "color";
            const responseObject = await axios.get(`${process.env.VUE_APP_API_URL}/database/tickets/${varname}/statistics`)
            const colorsUsed = responseObject.data.color;

            const distributionColors:number[] = [ticketStore.printingMaterials.length];
            for (let i = 0; i < ticketStore.printingMaterials.length; i++) {
                distributionColors[i] = 0;
            }

            for (let i = 0; i < colorsUsed.length; i++) {
                const color = colorsUsed[i];
                for (let j = 0; j < ticketStore.printingMaterials.length; j++) {
                    const colorName:any = ticketStore.printingMaterials[j];
                    if(color === colorName){
                        distributionColors[j] += 1;
                    }
                }
            }
            return distributionColors;
        }
    },

});