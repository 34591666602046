import { defineStore} from "pinia";
import en from '../other/lang/en.json';
import de from '../other/lang/de.json';
import emailjs from '@emailjs/browser';
import axios from "axios";

export const useManageStore = defineStore("manageStore", {
    state: () =>{
        return {
            //users
            baseUserImageUrl: "https://chat.wmaut.de/avatar/",
            baseEmail: "@wzl.rwth-aachen.de",
            showNavbar: false,
            userAssign: "",

            //email
            emailTemplates: {
                assigned: "template_99i7dlg",
                printed: "template_l0z6tfk"
            } as any,

            //filters
            filterTypes: ["all", "not assigned", "in progress", "printed"],
            selectedFilter: "all",
            
            showViewport: true,
            viewportLoaded: true,
            //global
            localeLang: "en",
            errorCode: null,
        };
    },
    actions: {
        lang(path: any){
            let result: {[index: string]:any} = {}
            if(this.localeLang == "en"){
                result = en;
            }
            else if(this.localeLang == "de"){
                result = de;
            }
            
            path.split(".").forEach(function(key:any){
                result = result[key];
              });
            return result;
        },
        /**
         * 
         * @param messageType The type of notification the message is (assigned, printed).
         * @param client The client, who created the ticket.
         * @param assignee The assignee, who usually changed the ticket status.
         * @param ticket_name The ticket name, which the status has changed.
         */
        sendNotification(messageType:string, client:string, assignee:string, ticket_name:string){
            const params = {
                client: client,
                assignee: assignee,
                ticket_name: ticket_name,
                email_id: client+"@wzl.rwth-aachen.de",
            }

            if(process.env.VUE_APP_EMAIL_TRAFFIC == "true") this.sendEmail(messageType, params);
            if(process.env.VUE_APP_CHAT_TRAFFIC == "true") this.sendChatMessage(messageType, params);
        },
        sendEmail(messageType:string, params:any){
            //send to client
            emailjs
            .send('service_c1w64tj', this.emailTemplates[messageType], params, {
                publicKey: 'BnH8DBgGXNXplTLdT',
            })
            .then(
                () => {
                    console.log(`SUCCESS! Email was send to ${params.to_name}`);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
            //send to assignee if client and assignee are not the same user
            if(params.client != params.assignee){
                params.client = params.assignee;
                emailjs
                .send('service_c1w64tj', this.emailTemplates[messageType], params, {
                    publicKey: 'BnH8DBgGXNXplTLdT',
                })
                .then(
                    () => {
                        console.log(`SUCCESS! Email was send to ${params.to_name}`);
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
            }
        },
        sendChatMessage(messageType:string, params:any){
            let message = "no valid message";

            if(messageType == "assigned"){
                message = `The Ticket *"${params.ticket_name}"* has been assigned to @${params.assignee}`;
            }
            else if(messageType == "printed"){
                message = `@${params.assignee} has *printed* the Ticket *"${params.ticket_name}"*. It is now ready for pick up`;
            }
            

            axios.post(`${process.env.VUE_APP_API_URL}/database/tickets/rocketchat/message`, {
                message: message,
                username: params.client
            })
            if(params.client != params.assignee){
                axios.post(`${process.env.VUE_APP_API_URL}/database/tickets/rocketchat/message`, {
                    message: message,
                    username: params.assignee
                })
            }
        },
        calcWeekFromNow(week:number){
            const date = new Date( Date.now() + (6.048e+8 * week) )
            return date.toISOString();
        }
    },

});