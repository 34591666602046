import { defineStore} from "pinia";
// @ts-ignore
import VueJWTDecode from 'vue-jwt-decode';
import Keycloak from "keycloak-js";
import axios from "axios";

export const useAuthStore = defineStore("authStore", {
    state: () =>{
        return {
            //user info
            given_name: "",
            family_name: "",
            email: "",
            email_verified: "",
            preferred_username: "",

            user_role: "",
            userRoles: ["client", "printer"],

            keycloak: new Keycloak(
                {
                    url: 'https://keycloak.wmaut.de',
                    realm: 'application',
                    clientId: 'printer-system',
                }
            ),
        };
    },
    actions: {
        decodeJWT(token:string){
            const decoded_token = VueJWTDecode.decode(token);

            this.given_name = decoded_token.given_name;
            this.family_name = decoded_token.family_name;
            this.email = decoded_token.email;
            this.email_verified = decoded_token.email_verified;
            this.preferred_username = decoded_token.preferred_username;
        },
        async fetchUserRole(user_short:string){
            try{
                const responseObject:any = await axios.get(`${process.env.VUE_APP_API_URL}/database/tickets/users/${user_short}`);
                this.user_role = responseObject.data.role;
            }
            catch(e){
                this.user_role = this.userRoles[0];
                this.postUser(this.preferred_username, this.userRoles[0]);
            }
           
        },
        postUser(user_short:string, user_role:string){
            axios.post(`${process.env.VUE_APP_API_URL}/database/tickets/users/`,
                {
                    user_short: user_short,
                    user_role: user_role,
                }
            );
        },
        logout(){
            this.keycloak.logout();
        }
    },

});